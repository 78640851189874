/* eslint-disable no-useless-return */
import { Divider, Grid, GridItem, Heading, Tooltip } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { RadioGroup } from '../../components/form'
import { AutocompleteOrTypeAsync } from '../../components/form/AutocompleteOrTypeAsync'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { getClientBusinessGroup } from '../../services/endpoints/clients/getClientsBusinessGroupSegment'
import { ClientType } from '../../services/types'
import { CnpjData, searchPersonByCnpj } from '../../services/utils/searchPersonByCnpj'

interface ClientFormProps {
  clientData?: ClientType
  formState: FormState<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

export function ClientForm({ clientData, formState, setValue }: ClientFormProps): JSX.Element {
  const [client, setClient] = useState<CnpjData>()
  const { errors } = formState
  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="4">
        <GridItem colSpan={[12, 3]}>
          <InputMask
            isRequired
            mask="99.999.999/9999-99"
            name="cnpj"
            label="CNPJ"
            error={errors.cnpj}
            setValue={setValue}
            onBlur={async e => {
              const cnpj = await searchPersonByCnpj(String(e.target.value))
              if (cnpj) {
                setClient(cnpj)
              }
            }}
            initialValue={client?.cnpj || clientData?.cnpj}
            registerOnlyNumbers
          />
        </GridItem>
        <GridItem colSpan={[12, 9]}>
          <Input
            isRequired
            name="name"
            label="Razão social"
            error={errors.name}
            setValue={setValue}
            initialValue={client?.name || clientData?.name}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Input
            isRequired
            name="nickname"
            label="Apelido"
            error={errors.nickname}
            setValue={setValue}
            initialValue={client?.nickname || clientData?.nickname}
          />
        </GridItem>
        <GridItem colSpan={[12, 8]}>
          <Input
            name="main_activity"
            label="Atividade principal"
            error={errors.main_activity}
            setValue={setValue}
            initialValue={client?.main_activity || clientData?.main_activity}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <Input
            name="email"
            type="email"
            label="E-mail"
            error={errors.email}
            setValue={setValue}
            initialValue={client?.email || clientData?.email}
          />
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <InputMask
            mask="(99) 9 9999-9999"
            maskPlaceholder=""
            name="phone"
            label="Telefone"
            error={errors.phone}
            setValue={setValue}
            initialValue={client?.phone || clientData?.phone}
            registerOnlyNumbers
          />
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Select
            name="payment_method"
            label="Tipo de pagamento"
            error={errors.payment_method}
            setValue={setValue}
            initialValue={clientData?.payment_method}
            options={[
              { label: '', value: '' },
              { label: 'Boleto', value: 'bill' },
              { label: 'Depósito', value: 'deposit' },
            ]}
            isRequired
          />
        </GridItem>
        <GridItem colSpan={12} />
        <GridItem colSpan={[12, 3]}>
          <InputMask
            mask="99.999-999"
            name="zipcode"
            label="CEP"
            error={errors.zipcode}
            setValue={setValue}
            initialValue={client?.zipcode || clientData?.zipcode}
            registerOnlyNumbers
          />
        </GridItem>
        <GridItem colSpan={[12, 7]}>
          <Input
            name="address"
            label="Endereço"
            error={errors.address}
            setValue={setValue}
            initialValue={client?.address || clientData?.address}
          />
        </GridItem>
        <GridItem colSpan={[12, 2]}>
          <Input
            name="address_number"
            label="Número"
            error={errors.address_number}
            setValue={setValue}
            initialValue={client?.address_number || clientData?.address_number}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Input
            name="district"
            label="Bairro"
            error={errors.district}
            setValue={setValue}
            initialValue={client?.district || clientData?.district}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Input
            name="city"
            label="Cidade"
            error={errors.city}
            setValue={setValue}
            initialValue={client?.city?.label || clientData?.city}
          />
        </GridItem>
        <GridItem colSpan={[12, 2]}>
          <Input
            name="state"
            label="UF"
            error={errors.state}
            setValue={setValue}
            initialValue={client?.address_uf || clientData?.state}
          />
        </GridItem>
      </Grid>
      <Divider mt={5} />
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={4}>
        <GridItem colSpan={[12, 12]}>
          <Heading size="sm">Grupo empresarial</Heading>
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <AutocompleteOrTypeAsync
            isRequired
            name="clientBusinessGroup.name"
            setValue={setValue}
            loadOptions={getClientBusinessGroup}
            error={errors?.clientBusinessGroup?.name}
            initialValue={
              clientData?.clientBusinessGroup?.name && clientData.clientBusinessGroup.id
                ? {
                    label: clientData.clientBusinessGroup.name,
                    value: clientData.clientBusinessGroup.id,
                  }
                : undefined
            }
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="pocket_insurance"
            label="Seguro Bolsão"
            error={errors.pocket_insurance}
            setValue={setValue}
            initialValue={clientData?.clientBusinessGroup?.pocket_insurance}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Tooltip label="Prazo de pagamento em dias" hasArrow placement="top">
            <Input
              name="payment_deadline"
              label="Prazo de pagamento"
              error={errors.payment_deadline}
              setValue={setValue}
              initialValue={clientData?.clientBusinessGroup?.payment_deadline}
            />
          </Tooltip>
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Input
            name="effective_insurance"
            label="Seguro efetivo"
            error={errors.effective_insurance}
            setValue={setValue}
            initialValue={clientData?.clientBusinessGroup?.effective_insurance}
          />
        </GridItem>
        <GridItem colSpan={[12, 2]}>
          <RadioGroup
            name="ddr"
            label="DDR"
            error={errors.ddr}
            setValue={setValue}
            direction="column"
            options={[
              { label: 'Sim', value: 1 },
              { label: 'Não', value: 0 },
            ]}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export const ClientFormSchema = yup.object().shape({
  cnpj: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  payment_method: yup.string().required('Campo obrigatório'),
  nickname: yup.string().required('Campo obrigatório'),
  main_activity: yup.string(),
  email: yup.string().email('Email inválido'),
  phone: yup.string(),
  zipcode: yup.string(),
  address: yup.string(),
  address_number: yup.number(),
  district: yup.string(),
  city: yup.string(),
  state: yup.string(),
  clientBusinessGroup: yup.object().shape({
    name: yup.object().shape({
      label: yup
        .string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Somente caracteres alfanuméricos são permitidos')
        .test(
          'not-only-spaces',
          'Campo não pode conter apenas espaços em branco',
          value => value?.trim() !== '',
        )
        .required('Campo obrigatório'),
    }),
  }),
  ddr: yup.boolean(),
  pocket_insurance: yup.string(),
  payment_deadline: yup.string(),
  effective_insurance: yup.string(),
})
